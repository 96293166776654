import {Action} from "../interfaces/redux";

export default function ToastReducer(state: {message: string} = {message: ""}, action: any): {message: string} {
  switch (action.type) {
    case Action.TOAST:
      return action.payload;
    default:
      return state;
  }
}
