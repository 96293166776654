import {Component} from "react";
import {connect} from "react-redux";
import {AlertSuccess} from "../../reducers/store";
import "../../static/css/fspopup.css";
import Icon from "../Icon";
import {store} from "src/App";
import {Action} from "src/interfaces/redux";

interface ToastProps {
  message?: string;
  error?: boolean;
}

interface ToastState {
  isVisible?: boolean;
}

class Toast extends Component<ToastProps, ToastState> {
  render() {
    if (this.props.message.length > 1) {
      setTimeout(() => {
        store.dispatch({
          type: Action.TOAST,
          payload: {
            message: "",
            error: false,
          },
        });
      }, 3000);
    }
    return (
      this.props.message.length > 1 && (
        <div className={`toast toast-animation z-50`}>
          <div className={`toast-message ${this.props.error ? "bg-destructive" : "bg-success"}`}>
            <span>{this.props.message}</span>
            <button className="toast-close-button" onClick={(e) => this.handleClosePopup(e)}>
              <Icon name="clear" />
            </button>
          </div>
        </div>
      )
    );
  }

  handleClosePopup(e) {
    AlertSuccess("");
  }
}

function mapStateToProps(state: {toast: {message: string; error?: boolean}}) {
  return {
    message: state.toast.message,
    error: state.toast.error,
  };
}

export default connect(mapStateToProps)(Toast);
