import {Category} from "../interfaces";
import {Action} from "../interfaces/redux";
import {ChildCategories, FlattenCategories} from "../utilities/utils";

export interface CategoryReducerState {
  categories: Category[];
  childCategories?: Category[];
  flattenCategories?: Category[];
}

export interface CategoryAction {
  type: Action;
  payload: CategoryReducerState;
}

const defaultState = {
  categories: [],
  childCategories: [],
  flattenCategories: [],
};

export function CategoryReducer(
  state: CategoryReducerState = defaultState,
  action: {type: Action; payload: CategoryReducerState},
): CategoryReducerState {
  switch (action.type) {
    case Action.CATEGORIES:
      return {
        categories: action.payload.categories,
        flattenCategories: FlattenCategories(action.payload.categories),
        childCategories: ChildCategories(action.payload.categories),
      };

    default:
      return state;
  }
}
