import React from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Parser from "html-react-parser";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props} />;
  };

  return Wrapper;
};

export const withParams = (Component) => {
  const Wrapper = (props) => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    return <Component params={params} searchParams={searchParams} {...props} />;
  };

  return Wrapper;
};

export const withHtmlParser = (Component) => {
  const Wrapper = (props) => {
    return <Component parser={Parser} getInnerText={getInnerText} {...props} />;
  };
  return Wrapper;
};

function getInnerText(htmlString: string) {
  var div = document.createElement("div");
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || "";
}
