import {KnowledgeBase} from "src/interfaces/knowledgeBase";
import {Action} from "src/interfaces/redux";

type KnowledgeBaseState = {
  knowledgeBases: KnowledgeBase[];
  currentKnowledgeBase: KnowledgeBase | null;
};

const initialState = {
  knowledgeBases: [],
  currentKnowledgeBase: null,
};

export default function KnowledgeBaseReducer(
  state: KnowledgeBaseState = initialState,
  action: any,
): KnowledgeBaseState {
  switch (action.type) {
    case Action.KNOWLEDGE_BASES:
      return {...state, knowledgeBases: action.payload};
    case Action.CURRENT_KNOWLEDGE_BASE:
      return {...state, currentKnowledgeBase: action.payload};
    default:
      return state;
  }
}
