import {LinearProgress} from "@mui/material";
import {Component, Suspense} from "react";
import {connect} from "react-redux";
import {SETTINGS_KEY} from "src/config/config";
import {getLocalStorageItem} from "src/utilities/cookie";
import {SettingsReducerPayload, UISettings} from "../interfaces/settings";
import Icon from "./Icon";
import {Button} from "./ui/button";

const Heading = (props: {title: string; iconName: string}) => {
  return (
    <div className="h-32 pl-16 bg-muted text-muted-foreground flex gap-8 items-center shadow">
      <Icon name={props.iconName} iconSize="30px" />
      <h1 className="text-3xl">{props.title}</h1>
    </div>
  );
};
const Container = (props) => {
  return <div className="main-container">{props.children}</div>;
};

const ContentContainer = (props) => {
  const expanded = getLocalStorageItem<SettingsReducerPayload>(SETTINGS_KEY, "{}", true)?.sidebarExpanded;
  return (
    <div className={`h-screen ${!expanded ? "content-container-contracted" : "content-container"}`}>
      {props.children}
    </div>
  );
};

const SuspenseWrap = (props) => {
  return <Suspense fallback={<LinearProgress />}>{props.container}</Suspense>;
};

interface FloatingMenuProps {
  iconHandlers?: Record<string, (e) => void>;
}

class FloatingMenu extends Component<FloatingMenuProps, {}> {
  render() {
    return (
      <div className={`floating-container z-50`}>
        {Object.keys(this.props.iconHandlers).map((icon, i) => {
          return (
            <Button
              className="rounded-full h-20 w-20 shadow-2xl"
              variant="default"
              onClick={(e) => this.props.iconHandlers[icon](e)}
              key={`floating-button-${i}`}
            >
              <Icon name={icon} iconSize="30px" />
            </Button>
          );
        })}
      </div>
    );
  }
}

class Header extends Component {
  render() {
    return <div className="header"></div>;
  }
}
function mapStateToProps(state: {settings: UISettings}) {
  return {
    sidebarExpanded: state.settings.sidebarExpanded,
  };
}

const ContentBox = connect(mapStateToProps)(ContentContainer);

export {Header, Heading, FloatingMenu, Container, ContentBox, SuspenseWrap};
