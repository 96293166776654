import React, {Component, CSSProperties} from "react";
import "../static/icomoon/style.css";

interface IconProps {
  name: string;
  iconSize?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: Function;
  color?: string;
  tooltip?: string;
}

export default class Icon extends Component<IconProps, {}> {
  public render(): React.ReactNode {
    return (
      <span
        title={this.props.tooltip || ""}
        className={`icon icon-${this.props.name} ${this.props.className || ""}`}
        style={{
          color: this.props.color || "inherit",
          fontSize: this.props.iconSize || "inherit",
          ...this.props.style,
        }}
        onClick={(e) => this.props.onClick && this.props.onClick()}
      ></span>
    );
  }
}
