import {Action} from "../interfaces/redux";

export interface AlertState {
  title: string;
  message: string;
  onConfirmHandler?: Function;
  onCloseHandler?: Function;
}

export default function AlertReducer(
  state: AlertState = {message: "", title: "", onConfirmHandler: null, onCloseHandler: null},
  action: any,
): {message: string} {
  switch (action.type) {
    case Action.CONFIRM:
      return action.payload;
    default:
      return state;
  }
}
