export enum Action {
  CONFIRM = "CONFIRM",
  TOAST = "TOAST",
  LOGIN = "LOGIN",
  VALIDATE = "VALIDATE",
  LOGOUT = "LOGOUT",
  SETTINGS = "SETTINGS",
  KNOWLEDGE_BASES = "KNOWLEDGE_BASES",
  CURRENT_KNOWLEDGE_BASE = "CURRENT_KNOWLEDGE_BASE",
  QUESTIONS = "QUESTIONS",
  CATEGORIES = "CATEGORIES",
  SET_LANGUAGE = "SET_LANGUAGE",
  CUSTOMER = "CUSTOMER",
  ASSISTANT_SETTINGS = "ASSISTANT_SETTINGS",
}

export interface SetLanguageAction {
  type: Action.SET_LANGUAGE;
  payload: string;
}

export type AppActions = SetLanguageAction;
