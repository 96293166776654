export function getCookie(key: string) {}

export function getLocalStorageItem<T>(key: string, defaultValue?: string, isJSON?: boolean): T {
  const value = localStorage.getItem(key);
  if (defaultValue && !value && !isJSON) {
    return defaultValue as T;
  } else if (!value && isJSON) {
    return JSON.parse(defaultValue);
  } else if (value && isJSON) {
    return JSON.parse(value);
  }

  return value as T;
}

export function setLocalStorageItem(key: string, value: string) {
  return localStorage.setItem(key, value);
}

export function removeLocalStorageItem(key: string) {
  return localStorage.removeItem(key);
}
