import React from "react";
import {
  SetAllQuestions,
  SetAllCategories,
  SetSettings,
  SetCurrentKnowledgeBase,
  SetAllKnowledgeBases,
} from "../reducers/store";
import {GetCategories, GetQuestions} from "../services";
import {SuspenseWrap} from "./Header";
import {GetKBSettings, GetKnowledgeBase} from "src/services/backend";
import {withParams, withRouter} from "src/utilities/hooks";
import {FlattenCategories} from "src/utilities/utils";
import {NavigateFunction} from "react-router-dom";

interface OpenRouteProps {
  navigate: NavigateFunction;
  container: JSX.Element;
  params: {
    uniqueId: string;
  };
}

interface OpenRouteState {
  inProgress: boolean;
}

class OpenRoute extends React.Component<OpenRouteProps, OpenRouteState> {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  render() {
    return (
      <SuspenseWrap
        container={React.cloneElement(this.props.container, {
          inProgress: this.state.inProgress,
          uniqueId: this.props.params?.uniqueId,
        })}
      />
    );
  }

  async getData() {
    try {
      const currentKnowledgeBase = await this.getKnowledgeBase(this.props.params.uniqueId);
      // const currentKnowledgeBase = knowledgeBases?.find((kb) => kb.uniqueId === this.props.params.uniqueId);

      if (currentKnowledgeBase.deleted || !currentKnowledgeBase) {
        this.props.navigate("/deleted");
        this.setState({inProgress: false});
        return;
      }

      SetCurrentKnowledgeBase(currentKnowledgeBase);

      await Promise.all([
        this.getCategories(currentKnowledgeBase.id),
        this.getQuestions(currentKnowledgeBase.id),
        this.getSettings(currentKnowledgeBase.id),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({inProgress: false});
    }
  }

  async getKnowledgeBase(uniqueId: string) {
    const response = await GetKnowledgeBase(uniqueId);

    SetCurrentKnowledgeBase(response.data);
    SetAllKnowledgeBases([response.data]);
    return response.data;
  }

  // async getKnowledgeBases() {
  //   const response = await GetKnowledgeBases();
  //   return response.data;
  // }

  async getQuestions(knowledgeBaseId: number) {
    const response = await GetQuestions(knowledgeBaseId);
    SetAllQuestions(response);
  }

  async getCategories(knowledgeBaseId: number) {
    const response = await GetCategories(knowledgeBaseId);
    const allCategories = FlattenCategories(response);

    SetAllCategories(allCategories);
  }

  async getSettings(knowledgeBaseId: number) {
    const response = await GetKBSettings(knowledgeBaseId);
    SetSettings(response);
  }
}

export default withRouter(withParams(OpenRoute));
