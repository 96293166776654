import {Category} from "../interfaces";
import {parse} from "node-html-parser";
import {CountryCode, parsePhoneNumber} from "libphonenumber-js";
import {NavigateFunction} from "react-router-dom";
import {ASSISTANT_SUPPORTED_FILE_TYPES} from "src/config/constants";

export const CopyToClipboard = (text: string) => {
  navigator?.clipboard?.writeText(text);
  return true;
};

export const FlattenCategories = (categories: Category[]) => {
  const flattenCategories = [];
  const flatten = (categories: Category[]) => {
    categories.forEach((category) => {
      flattenCategories.push(category);
      if (category.childrenCategories?.length > 0) {
        flatten(category.childrenCategories);
      }
    });
  };
  flatten(categories);

  return flattenCategories;
};

export const ChildCategories = (categories: Category[]) => {
  const childCategories = [];
  const flatten = (categories: Category[]) => {
    categories.forEach((category) => {
      if (category.childrenCategories?.length > 0) {
        flatten(category.childrenCategories);
      } else {
        childCategories.push(category);
      }
    });
  };
  flatten(categories);

  return childCategories;
};

export const GetHierarchicalCategories = (categories, language: string, parentId = 0, level = 0) => {
  let result = [];
  categories.forEach((category) => {
    const categoryName = category.langs[language]?.name;
    if (category.parentId === parentId) {
      result.push({
        title: `${"— ".repeat(level)}${categoryName}`,
        value: category.id,
      });
      result = result.concat(GetHierarchicalCategories(categories, language, category.id, level + 1));
    }
  });
  return result;
};

export function modifyHtmlLinks(htmlContent: string = "") {
  const root = parse(htmlContent);
  const links = root.querySelectorAll("a");
  links.forEach((link) => {
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
  });

  const internalLinkRegex = /\{\{(form|question|category),\s*(\d+),\s*([^}]+)\}\}/g; // {{type, id, displayText}}
  htmlContent = root.toString().replace(internalLinkRegex, (match, type, id, displayText) => {
    if (type === "question") {
      return `<button class="internal-link-question text-blue-500" data-key="${encodeURIComponent(
        id.trim(),
      )}">${displayText.trim()}</button>`;
    } else if (type === "category") {
      return `<button class="internal-link-category text-blue-500" data-key="${encodeURIComponent(
        id.trim(),
      )}">${displayText.trim()}</button>`;
    } else if (type === "form") {
      return `<button class="internal-link-form text-blue-500" data-key="${encodeURIComponent(
        id.trim(),
      )}">${displayText.trim()}</button>`;
    }
    return match;
  });

  return htmlContent;
}

export const phoneNumberValidateAndParse = (phoneNumber: string, countryCode?: CountryCode) => {
  try {
    const parsed = phoneNumber ? parsePhoneNumber(phoneNumber, {defaultCountry: countryCode}) : undefined;
    return parsed?.isValid() ? parsed : undefined;
  } catch (error) {
    if (error) {
      console.error(error);
    }
    return undefined;
  }
};

export const getCountryFlag = (country: string) => {
  if (!country) return null;

  return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/${country.toLowerCase()}.svg`;
};

export function Redirect(navigate: NavigateFunction, condition: boolean, path: string) {
  if (condition) navigate(path);
}

export const makeFileFromJson = <T>(data: T, fileName: string) => {
  const jsonString = JSON.stringify(data);
  const blob = new Blob([jsonString], {type: "application/json"});
  const file = new File([blob], fileName, {
    type: "application/json",
  });
  return file;
};

export const isValidAssistantFileType = (file: File): boolean => {
  return ASSISTANT_SUPPORTED_FILE_TYPES.some((type) => file.type.includes(type));
};
