export const Config = {
  host: process.env.REACT_APP_API_URL,
  atlas: process.env.REACT_APP_ATLAS_URL,
  basepath: process.env.REACT_APP_API_BASEPATH,
  chatDomain: "https://motion-dev.connectel.io:443",
  assistantApi: process.env.REACT_APP_ASSISTANT_API_DOMAIN,
  superadminSSOCookieDomain: process.env.REACT_APP_SUPERADMIN_SSO_COOKIE_DOMAIN,
  domain: window.origin,
};

export const USER_KEY = "user";
export const SETTINGS_KEY = "settings";
export const AUTH_TOKEN_KEY = "auth-token";
export const ASSISTANT_AUTH_TOKEN_KEY = "assistant-auth-token";
