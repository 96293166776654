import { Customer } from "src/interfaces/customer";
import {Action} from "src/interfaces/redux";

export interface CustomerState {
  customer: Customer
};

const initialState: CustomerState = {
  customer: null
};

export default function CustomerReducer(
  state: CustomerState = initialState,
  action: {type: string, payload: Customer},
): CustomerState {
  switch (action.type) {
    case Action.CUSTOMER:
      return {...state, customer: action.payload};      
    default:
      return state;
  }
}
