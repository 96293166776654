import {AssistantSettings, KBSettings} from "src/interfaces/settings";
import {store} from "../App";
import {Auth, Category, Question} from "../interfaces";
import {Action} from "../interfaces/redux";
import {KnowledgeBase} from "src/interfaces/knowledgeBase";
import {Customer} from "src/interfaces/customer";

export function AlertSuccess(message: string) {
  store.dispatch({
    type: Action.TOAST,
    payload: {
      message,
    },
  });
}

export function AlertError(message: string) {
  store.dispatch({
    type: Action.TOAST,
    payload: {
      message,
      error: true,
    },
  });
}

export function PopupAlertConfirm(title: string, message?: string, onConfirmHandler?: any, onCloseHandler?: any) {
  store.dispatch({
    type: Action.CONFIRM,
    payload: {
      title,
      message,
      onConfirmHandler,
      onCloseHandler,
    },
  });
}

export function SetAllKnowledgeBases(knowledgeBases: KnowledgeBase[]) {
  store.dispatch({
    type: Action.KNOWLEDGE_BASES,
    payload: knowledgeBases,
  });
}

export function SetCurrentKnowledgeBase(knowledgeBase: KnowledgeBase) {
  store.dispatch({
    type: Action.CURRENT_KNOWLEDGE_BASE,
    payload: knowledgeBase,
  });
}

export function SetValidate(validateData: Auth) {
  store.dispatch({
    type: Action.VALIDATE,
    payload: {
      token: validateData.token,
      assistantAPIToken: validateData.assistantAPIToken,
      user: validateData.user,
    },
  });
}

export function Logout() {
  store.dispatch({
    type: Action.LOGOUT,
  });
}

export function SetAllQuestions(questions: Question[]) {
  store.dispatch({
    type: Action.QUESTIONS,
    payload: questions,
  });
}

export function SetAllCategories(categories: Category[]) {
  store.dispatch({
    type: Action.CATEGORIES,
    payload: {
      categories,
    },
  });
}

export function SetSettings(settings: KBSettings) {
  store.dispatch({
    type: Action.SETTINGS,
    payload: {settings},
  });
}

export function SetAssistantSettings(assistantSettings: AssistantSettings) {
  store.dispatch({
    type: Action.ASSISTANT_SETTINGS,
    payload: assistantSettings,
  });
}

export function SetLanguage(language: string) {
  store.dispatch({
    type: Action.SET_LANGUAGE,
    payload: language,
  });
}

export function SetCustomer(customer: Customer) {
  store.dispatch({
    type: Action.CUSTOMER,
    payload: customer,
  });
}
