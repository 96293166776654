import {Action} from "../interfaces/redux";
import {AssistantSettings} from "../interfaces/settings";

export default function AssistantSettingsReducer(
  state: AssistantSettings = {assistants: []},
  action: {type: Action; payload: AssistantSettings},
): AssistantSettings {
  switch (action.type) {
    case Action.ASSISTANT_SETTINGS:
      return action.payload;
    default:
      return state;
  }
}
