import {Action, AppActions} from "src/interfaces/redux";

const defaultState = {
  language: navigator.language || "en",
};

const LanguageReducer = (state = defaultState, action: AppActions) => {
  switch (action.type) {
    case Action.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

export default LanguageReducer;
