import {combineReducers} from "redux";
import AlertReducer from "./alert";
import AuthReducer from "./auth";
import {CategoryReducer} from "./categories";
import QuestionsReducer from "./questions";
import SettingsReducer from "./settings";
import ToastReducer from "./toast";
import KnowledgeBaseReducer from "./knowledgeBase";
import LanguageReducer from "./language";
import CustomerReducer from "./customer";
import AssistantSettingsReducer from "./assistantSettings";

export default combineReducers({
  knowledgeBase: KnowledgeBaseReducer,
  questions: QuestionsReducer,
  categories: CategoryReducer,
  auth: AuthReducer,
  settings: SettingsReducer,
  assistantSettings: AssistantSettingsReducer,
  toast: ToastReducer,
  alert: AlertReducer,
  language: LanguageReducer,
  customer: CustomerReducer,
});
