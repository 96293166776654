import {Question} from "../interfaces/questions";
import {Action} from "../interfaces/redux";

export default function QuestionsReducer(state: Array<Question> = [], action: any): Array<Question> {
  switch (action.type) {
    case Action.QUESTIONS:
      return action.payload;
    default:
      return state;
  }
}
