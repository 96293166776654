import axios from "axios";
import {getLocalStorageItem} from "src/utilities/cookie";
import {ASSISTANT_AUTH_TOKEN_KEY, AUTH_TOKEN_KEY, Config} from "../config/config";
import {
  LoginUser,
  LoginUserBySSOToken,
  CreateUser,
  GetUsers,
  GetKBSettings,
  UpdateKBSettings,
  GetCategories,
  CreateCategory,
  UpdateCategory,
  UpdateCategoryViews,
  DeleteCategory,
  UpdateQuestion,
  GetQuestion,
  GetQuestions,
  SearchQuestions,
  PostQuestion,
  GetQuestionsByCategory,
  GetCategory,
  PostMail,
} from "./backend";

const Backend = axios.create({
  baseURL: Config.host,
});

Backend.interceptors.request.use((config) => {
  const tokens = getLocalStorageItem(AUTH_TOKEN_KEY);
  if (tokens !== null) {
    config.headers["Authorization"] = `Bearer ${tokens}`;
  }
  return config;
});

const Assistant = axios.create({
  baseURL: Config.assistantApi,
});

Assistant.interceptors.request.use((config) => {
  const token = getLocalStorageItem(ASSISTANT_AUTH_TOKEN_KEY);
  if (token !== null) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const Atlas = axios.create({
  baseURL: Config.atlas,
});

export {
  Atlas,
  Backend,
  Assistant,
  LoginUser,
  LoginUserBySSOToken,
  GetUsers,
  CreateUser,
  GetKBSettings,
  UpdateKBSettings,
  GetCategories,
  GetCategory,
  CreateCategory,
  UpdateCategory,
  UpdateCategoryViews,
  DeleteCategory,
  GetQuestion,
  GetQuestions,
  SearchQuestions,
  PostQuestion,
  GetQuestionsByCategory,
  UpdateQuestion,
  PostMail,
};
