import React from "react";
import {Translation} from "react-i18next";
import Router from "./Router";
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import {createStore, applyMiddleware} from "redux";
import reducers from "./reducers";
import Toast from "./components/form/Toast";
import Alert from "./components/form/Alert";
import "../src/static/css/tailwind.css";
import "./i18n";

const store: any = createStore(reducers, applyMiddleware(thunk));

function App() {
  return (
    <Translation>
      {(t) => (
        <Provider store={store}>
          <Router />
          <Toast />
          <Alert />
        </Provider>
      )}
    </Translation>
  );
}

export default App;
export {store};
