import { ASSISTANT_AUTH_TOKEN_KEY, AUTH_TOKEN_KEY, USER_KEY } from "src/config/config";
import { removeLocalStorageItem, setLocalStorageItem } from "src/utilities/cookie";
import {Auth} from "../interfaces/";
import {Action} from "../interfaces/redux";

export default function AuthReducer(state: Auth = {}, action: any): Auth {
  switch (action.type) {
    case Action.LOGIN:
    case Action.VALIDATE:
      setLocalStorageItem(AUTH_TOKEN_KEY, action.payload.token);
      setLocalStorageItem(ASSISTANT_AUTH_TOKEN_KEY, action.payload.assistantAPIToken);
      setLocalStorageItem(USER_KEY, JSON.stringify(action.payload.user));
      return action.payload;
    case Action.LOGOUT:
      removeLocalStorageItem(AUTH_TOKEN_KEY);
      removeLocalStorageItem(ASSISTANT_AUTH_TOKEN_KEY)
      removeLocalStorageItem(USER_KEY);
      window.location.href = "/login";
      return {};
    default:
      return state;
  }
}
