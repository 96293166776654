import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Component} from "react";
import {connect} from "react-redux";
import {AlertState} from "../../reducers/alert";

interface AlertProps {
  title: string;
  message: string;
  onConfirmHandler: (e) => void;
  onCloseHandler: (e) => void;
}

class Alert extends Component<AlertProps> {
  render() {
    // const open = this.props.title?.length > 0 || this.props.message?.length > 0;
    return (
      this.props.title?.length > 0 &&
      this.props.message?.length > 0 && (
        <Dialog
          open={true}
          onClose={this.props.onCloseHandler}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{this.props.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.props.onCloseHandler(e)} autoFocus>
              Close
            </Button>
            <Button onClick={(e) => this.props.onConfirmHandler(e)}>Delete</Button>
          </DialogActions>
        </Dialog>
      )
    );
  }
}

function mapStateToProps(state: {alert: AlertState}) {
  return {
    title: state.alert.title,
    message: state.alert.message,
    onConfirmHandler: state.alert.onConfirmHandler,
    onCloseHandler: state.alert.onCloseHandler,
  };
}
export default connect(mapStateToProps)(Alert);
